<template>
  <div class="card">
    <div class="card-body">
      <div
        style="display:flex;justify-content:space-between;align-items:center;"
      >
        <div style="width:30%;margin-left:10px">
          <b-form-group label="Post code *">
            <b-form-input
              v-model="postcode"
              type="number"
              @keypress="IsNumber"
              placeholder="Please type post code"
            ></b-form-input>
          </b-form-group>
        </div>
        <div style="width:30%;margin-left:10px">
          <b-form-group label="Delivery Cost *">
            <b-form-input
              :formatter="lengthValidation"
              v-model="deliveryCost"
              type="number"
              @keypress="IsNumber"
              placeholder="Enter Delivery Cost "
            ></b-form-input>
          </b-form-group>
        </div>
        <div style="width:30%;margin-left:10px">
          <b-form-group label="Minimun Order Amount *">
            <b-form-input
              :formatter="lengthValidation"
              @keypress="IsNumber"
              v-model="minimumOrderAmount"
              type="number"
              placeholder="Enter Minimun Order Amount"
            ></b-form-input>
          </b-form-group>
        </div>
        <div style="width:10%;padding-left:10px">
          <a class="btn btn-info" @click="appendRow">Add</a>
        </div>
      </div>
      <hr />
      <div class="row mb-md-2">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>

      <div class="table-responsive mb-0">
        <b-table
          :items="deliveryAddress"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          ref="selectableTable"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #head(id)>
            <input v-model="isSelectAll" type="checkbox" />
          </template>
          <template v-slot:cell(id)="deliveryAddress">
            <input
              type="checkbox"
              :value="deliveryAddress.item.id"
              v-model="checkedNames"
            />
          </template>

          <template v-slot:cell(actions)="deliveryAddress">
            <i
              style="color:red;cursor: pointer;"
              class="fas fa-trash-alt"
              @click="deleteData(deliveryAddress)"
            ></i>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import Vue from "vue";

export default {
  components: {
    // Multiselect,
    // VueGoogleAutocomplete,
  },
  props: {
    deliveryAddress: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkedNames: [],
      postcode: "",
      deliveryAddresstempArray: "",
      deliveryAddressArray: [],
      deliveryCost: "",
      minimumOrderAmount: "",
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "id", label: "Select", sortable: true },
        // { key: "lat", label: "Lat", sortable: true },
        // { key: "lng", label: "lng", sortable: true },
        { key: "postcode", label: "Post code", sortable: true },
        // { key: "state", label: "State", sortable: true },
        // { key: "street", label: "Street", sortable: true },
        { key: "delivery_cost", label: "Delivery Cost", sortable: true },
        {
          key: "minimum_order_amount",
          label: "Minimum Order Amount",
          sortable: true,
        },

        { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {},

  computed: {
    rows() {
      return this.deliveryAddress.length;
    },
  },

  methods: {
    IsNumber(e) {
      const key = e.key;
      if (key === ".") return e.preventDefault();
      if (key === "e") return e.preventDefault();
    },

    lengthValidation(e) {
      return String(e).substring(0, 4);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.deliveryCost.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.deliveryCost != null &&
        this.deliveryCost.indexOf(".") > -1 &&
        this.deliveryCost.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    // deliveryAddresses: function(addressData) {
    //   // var parts = id.split("_");
    //   // let data = {
    //   //   postcode: addressData.postal_code,
    //   //   street: addressData.route,
    //   //   city: addressData.locality,
    //   //   state: addressData.administrative_area_level_1,
    //   //   lat: addressData.latitude,
    //   //   lng: addressData.longitude,
    //   // };
    //   // this.deliveryAddresstempArray.splice(parts[1], 1);
    //   // this.deliveryAddresstempArray = data;
    // },

    appendRow() {
      if (this.postal_code == "") {
        this.toasterMessageFalse("Post code is required");
        return false;
      }
      // if (this.deliveryAddresstempArray == "") {
      //   this.toasterMessageFalse("Delivery Address required");
      //   return false;
      // }
      if (this.deliveryCost == "") {
        this.toasterMessageFalse("Delivery cost required");
        return false;
      }

      if (this.minimumOrderAmount == "") {
        this.toasterMessageFalse("Minimum  Order Amount required");
        return false;
      }
      let filtered = this.deliveryAddress.filter(
        (t) => t.postcode === this.postcode
      );
      if (filtered.length > 0) {
        this.toasterMessageFalse("Post code already exist");
        return false;
      }
      let data = {
        postcode: this.postcode,
        // street: this.deliveryAddresstempArray.street,
        // city: this.deliveryAddresstempArray.city,
        // state: this.deliveryAddresstempArray.state,
        // lat: this.deliveryAddresstempArray.lat,
        // lng: this.deliveryAddresstempArray.lng,
        delivery_cost: this.deliveryCost,
        minimum_order_amount: this.minimumOrderAmount,
      };
      // this.$refs.autocomplete.clear();
      this.deliveryAddress.push(data);
      this.delivery_cost = "";
      this.minimum_order_amount = "";
      // this.deliveryAddresstempArray = "";
      this.deliveryCost = "";
      this.minimumOrderAmount = "";
      this.postcode = "";
    },

    toasterMessageSuccess(message) {
      Vue.$toast.success(message, {
        position: "top-right",
        duration: 5000,
      });
    },
    toasterMessageFalse(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object) {
      this.$emit(
        "showBulkPopupDeliveryAddress",
        object,
        "delete-delivery-address"
      );
    },
  },
};
</script>
