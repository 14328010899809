<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <router-link to="/restaurant">
                <button class="btn btn-info rounded-pill mb-2">
                  Back
                </button>
              </router-link>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
              <br />
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                class="alert alert-danger"
              >
                {{ alertText }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-2"
                label="Restaurant Name *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="name"
                  type="text"
                  required
                  placeholder="Enter Restaurant Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="showrestaurantUserDropDown"
                id="input-group-3"
                label="Restaurant User *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="customer"
                  :options="customers"
                  placeholder="Select Restaurant User"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.username }}({{ option.email }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                v-if="showrestaurantUserDropDown"
                id="input-group-3"
                label="Restaurant Categories *"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabelRcategory"
                  label="username"
                  :multiple="true"
                  v-model="restaurant_category"
                  :options="restaurant_categories"
                  placeholder="Select Restaurant Category"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.name }}</strong></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Current Address *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="location"
                  type="text"
                  required
                  placeholder="Enter Current Address"
                ></b-form-input>
              </b-form-group>

              <!-- <b-form-group
                id="input-group-2"
                label="Current Address *"
                label-for="input-2"
              >
                <vue-google-autocomplete
                  id="currentAddress"
                  ref="currentAddress"
                  classname="form-control"
                  placeholder="Enter Current Address"
                  v-model="currentAddressname"
                  v-on:placechanged="CurrentAddress"
                >
                </vue-google-autocomplete>
              </b-form-group> -->

              <!-- <b-form-group
                id="input-group-2"
                label="Opening Time *"
                label-for="input-2"
              >
                <b-form-timepicker
                  aria-required="true"
                  required
                  v-model="opening_time"
                  hours="24"
                  locale="en"
                ></b-form-timepicker>
              </b-form-group> -->

              <h4>Lunch:</h4>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Start Time"
                    label-for="input-2"
                  >
                    <vue-timepicker
                      required
                      format="HH:mm"
                      v-model="launch_start_time"
                    ></vue-timepicker>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="End Time"
                    label-for="input-2"
                  >
                    <vue-timepicker
                      required
                      format="HH:mm"
                      v-model="launch_end_time"
                    ></vue-timepicker>
                  </b-form-group>
                </div>
              </div>
              <h4>Dinner:</h4>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Start Time"
                    label-for="input-2"
                  >
                    <vue-timepicker
                      required
                      format="HH:mm"
                      v-model="dinner_start_time"
                    ></vue-timepicker>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="End Time"
                    label-for="input-2"
                  >
                    <vue-timepicker
                      required
                      format="HH:mm"
                      v-model="dinner_end_time"
                    ></vue-timepicker>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Opening Time "
                    label-for="input-2"
                  >
                    <vue-timepicker
                      format="HH:mm"
                      v-model="opening_time"
                    ></vue-timepicker>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Closing Time "
                    label-for="input-2"
                  >
                    <vue-timepicker
                      format="HH:mm"
                      v-model="closing_time"
                    ></vue-timepicker>
                  </b-form-group>
                </div>
              </div>

              <b-form-group
                id="input-group-3"
                label="Average Delivery Time *"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="deliveryTime"
                  :options="deliveryTimes"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Guten Percentage *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="guten_percentage"
                  @keypress="onlyForCurrency"
                  required
                  placeholder="Guten Percentage"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Contact Number *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="phone_number"
                  type="number"
                  required
                  placeholder="Enter Contact Number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Short Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="short_description"
                  placeholder="Enter Short Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Long Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="long_description"
                  placeholder="Enter Long Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group label="Type *">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="type"
                  name="radio-options"
                >
                  <b-form-radio value="1">Delivery</b-form-radio>
                  <b-form-radio value="0">Pickup</b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Cover image *"
                label-for="input-2"
              >
                <input type="file" @change="coverImageShow" /><br /><br />
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  v-if="coverimageUrl"
                  :src="coverimageUrl"
                />
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Profile image *"
                label-for="input-2"
              >
                <input type="file" @change="profileImageShow" /><br /><br />
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  v-if="profileimageUrl"
                  :src="profileimageUrl"
                />
              </b-form-group>

              <router-link to="/restaurantorders" v-if="orderButton">
                <b-button class="btn-info" style="margin-right: 13px;"
                  >Orders</b-button
                >
              </router-link>
            </div>
          </div>
        </div>
        <br /><br />
        <template v-if="isShowModel">
          <VerficationModal
            :show="showModal"
            :userObj="userObj"
            :actionType="actionType"
            @deleteImageRecord="deleteImageRecord"
            @deleteDeliveryAddress="deleteDeliveryAddress"
            @closeModal="closeModal"
          />
        </template>
        <template>
          <DeliveryAddressView
            :deliveryAddress="deliveryAddressArray"
            @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
          />
        </template>
        <br /><br />
        <div class="card">
          <input
            style="padding:10px"
            type="file"
            ref="file"
            multiple="multiple"
            @change="submitFiles"
          />
          <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
            <b-row>
              <b-col-4
                v-for="(image, key) in imagesArray"
                :key="key"
                class="p-3"
              >
                <button
                  style="color:white"
                  class="close ml-1"
                  @click.prevent="removeImage(image)"
                >
                  &times;
                </button>
                <b-img
                  style="height: 150px;max-width: 165px;"
                  thumbnail
                  fluid
                  :src="getLogo(image)"
                  alt="Image 1"
                ></b-img>
              </b-col-4>
            </b-row> </b-container
          ><br />

          <template>
            <ImagesViewTable @deleteData="deleteData" :loans="loans" />
          </template>

          <div class="col-sm-2 col-md-2">
            <b-button class="btn-info" ref="save" type="submit">Save</b-button>
          </div>
          <br />
        </div>

        <template v-if="showKitchenDetailModal">
          <KitchenDetail
            @closeModal="closeModal"
            :email="email"
            :username="username"
          />
        </template>

        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import store from "@/core/services/store/index.js";

// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import VerficationModal from "./VerficationModal";
import KitchenDetail from "./KitchenDetail";
import ImagesViewTable from "@/view/pages/Restaurant/ImagesViewTable";
import DeliveryAddressView from "@/view/pages/Restaurant/DeliveryAddressView";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    KitchenDetail,
    VueTimepicker,
    Multiselect,
    VerficationModal,
    // VueGoogleAutocomplete,
    ImagesViewTable,
    DeliveryAddressView,
  },
  data() {
    return {
      current_address: [],
      location: "",
      type: "1",
      guten_percentage: null,
      deliveryAddressArray: [],
      showrestaurantUserDropDown: false,
      deliveryAddresstempArray: [],
      orderButton: false,
      opening_time: "",
      closing_time: "",
      launch_start_time: "",
      launch_end_time: "",
      dinner_start_time: "",
      dinner_end_time: "",
      average_delivery_time: "",
      headerTitle: "Add Restaurant",
      long_description: "",
      deliveryCost: "",
      minimumOrderAmount: "",
      currentAddressname: "",
      short_description: "",
      phone_number: "",
      name: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      customers: [],
      restaurant_categories: [],
      restaurant_category: null,
      customer: null,
      imagesShow: false,
      changeClasss: false,
      showKitchenDetailModal: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      coverimageUrl: null,
      coverImage: null,
      profileimageUrl: null,
      profileImage: null,
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      price: "",
      show: true,

      deliveryTime: null,
      deliveryTimes: [
        { text: "Select Delivery Time", value: null },
        "30 Min",
        "60 Min",
        "90 Min",
        "120 Min",
      ],
    };
  },

  mounted() {
    document.title = "Add Restaurant ";
    if (store.state.loginUserType == "superadmin") {
      this.showrestaurantUserDropDown = true;
    }
    this.getRestaurantCustomer();
    this.getRestaurantCategory();
    if (this.$router.history.current.path == "/editrestaurant") {
      document.title = "Edit Restaurant ";
      this.orderButton = true;
      this.showImagesViewTable = true;
      this.headerTitle = "Edit Resturant";
      this.editDataApppend();
    }
    for (let ref in this.$refs) {
      this.$refs[ref].focus();
    }
  },
  methods: {
    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.guten_percentage.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.guten_percentage != null &&
        this.guten_percentage.indexOf(".") > -1 &&
        this.guten_percentage.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },
    editDataApppend() {
      this.editRestaurant = JSON.parse(localStorage.getItem("editRestaurant"));

      if (this.editRestaurant.coverImage) {
        this.coverimageUrl = this.editRestaurant.coverImage;
        this.coverImage = this.editRestaurant.coverImage;
      }

      if (this.editRestaurant.coverImage) {
        this.profileimageUrl = this.editRestaurant.profile_image;
        this.profileImage = this.editRestaurant.profile_image;
      }
      this.customer = this.editRestaurant.customerDetail;
      this.loans = this.editRestaurant.images;
      this.id = this.editRestaurant.id;
      this.name = this.editRestaurant.name;
      this.type = this.editRestaurant.type;
      if (this.editRestaurant.type == "Pickup") {
        this.type = "0";
      } else {
        this.type = "1";
      }
      this.phone_number = this.editRestaurant.phone_number;
      // this.currentAddressname = this.editRestaurant.location;
      this.short_description = this.editRestaurant.short_description;
      this.long_description = this.editRestaurant.long_description;
      this.restaurant_category = this.editRestaurant.categories.map(
        (x) => x.restaurant_categories
      );
      // let data = {
      //   postcode: this.editRestaurant.postcode,
      //   street: this.editRestaurant.street,
      //   city: this.editRestaurant.city,
      //   state: this.editRestaurant.state,
      //   lat: this.editRestaurant.lat,
      //   lng: this.editRestaurant.lng,
      // };
      this.location = this.editRestaurant.current_address;
      this.deliveryAddressArray = this.editRestaurant.addresses;
      this.opening_time = this.editRestaurant.opening_time;
      this.guten_percentage = this.editRestaurant.guten_percentage;
      this.closing_time = this.editRestaurant.closing_time;
      this.deliveryTime = this.editRestaurant.average_delivery_time;

      this.launch_start_time = this.editRestaurant.launch_start_time;
      this.launch_end_time = this.editRestaurant.launch_end_time;
      this.dinner_start_time = this.editRestaurant.dinner_start_time;
      this.dinner_end_time = this.editRestaurant.dinner_end_time;

      // this.deliveryAddressView = true;
    },

    deliveryAddress: function(addressData, placeResultData, id) {
      var parts = id.split("_");
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.deliveryAddresstempArray.splice(parts[1], 1);
      this.deliveryAddresstempArray.push(data);
    },
    appendRow() {
      if (this.deliveryCost == "") {
        this.toasterMessageFalse("Delivery cost required");
        return false;
      }

      if (this.minimumOrderAmount == "") {
        this.toasterMessageFalse("Minimum  Order Amount required");
        return false;
      }
      this.deliveryAddressArray.push(this.deliveryAddresstempArray[0]);
      this.$refs.autocomplete.clear();
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
    },

    KitchenDetailModal() {
      if (this.$router.history.current.path == "/editrestaurant") {
        if (this.editRestaurant.kitchen != null) {
          this.email = this.editRestaurant.kitchen.email;
          this.username = this.editRestaurant.kitchen.username;
        }
      }

      if (this.$router.history.current.path == "/addrestaurant") {
        this.email = "";
        this.username = "";
      }

      this.showKitchenDetailModal = true;
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    toasterMessageSuccess(message) {
      Vue.$toast.success(message, {
        position: "top-right",
        duration: 5000,
      });
    },
    toasterMessageFalse(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    closeModal(value) {
      this.isShowModel = value;
      this.showKitchenDetailModal = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteDeliveryAddress(object) {
      let index = this.deliveryAddressArray.findIndex(
        (x) => x.lat === object.item.lat
      );
      this.deliveryAddressArray.splice(index, 1);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },
    CurrentAddress: function(addressData) {
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.current_address = data;
    },
    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },

    customLabelRcategory({ name }) {
      return `${name}`;
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        const formData = new FormData();

        if (store.state.loginUserType == "superadmin") {
          if (this.customer == null) {
            this.validationMessage("User Id is Required");
            evt.preventDefault();
            return false;
          }
        }

        if (this.location === "") {
          this.validationMessage("Current Address is Required");
          evt.preventDefault();
          return false;
        }

        if (this.coverImage == null) {
          this.validationMessage("Cover Image is Required");
          evt.preventDefault();
          return false;
        }

        // if (this.deliveryAddressArray.length == 0) {
        //   this.validationMessage("Delivery Address is Required");
        //   evt.preventDefault();
        //   return false;
        // }

        formData.append("id", this.id);
        if (store.state.loginUserType == "superadmin") {
          formData.append("customer_id", this.customer.id);
        } else {
          formData.append("customer_id", store.state.LoginUserId);
        }
        evt.preventDefault();
        this.addLoaderClasses("save");
        formData.append("name", this.name);
        formData.append("short_description", this.short_description);
        formData.append("opening_time", this.opening_time);
        formData.append("closing_time", this.closing_time);
        formData.append("launch_start_time", this.launch_start_time);
        formData.append("launch_end_time", this.launch_end_time);
        formData.append("dinner_start_time", this.dinner_start_time);
        formData.append("dinner_end_time", this.dinner_end_time);
        formData.append("phone_number", this.phone_number);
        formData.append("average_delivery_time", this.deliveryTime);
        formData.append("guten_percentage", this.guten_percentage);
        formData.append("long_description", this.long_description);
        formData.append("type", this.type);
        formData.append("current_address", this.location);
        formData.append(
          "restaurant_category",
          this.restaurant_category.map((x) => x.id)
        );
        formData.append(
          "deliveryAddresses",
          JSON.stringify(this.deliveryAddressArray)
        );
        formData.append("images", JSON.stringify(this.imagesArray));
        if (this.coverImage) {
          formData.append("coverImage", this.coverImage);
        }
        if (this.profileImage) {
          formData.append("profile_image", this.profileImage);
        }

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/restaurant/store", formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "resturant" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            response.data.data.map((x) => {
              this.imagesArray.push(x);
            });
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },
    coverImageShow(e) {
      const file = e.target.files[0];
      this.coverImage = file;
      this.coverimageUrl = URL.createObjectURL(file);
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getRestaurantCustomer() {
      const data = { type: "restaurant" };
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/list", data, {
            headers,
          })
          .then((response) => {
            this.customers = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getRestaurantCategory() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .get(this.$path + "/user/restaurant_categories", "", {
            headers,
          })
          .then((response) => {
            this.restaurant_categories = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
.vue__time-picker .display-time {
}
.vue__time-picker {
  width: 100% !important;
}
</style>
